<template>
  <el-dialog
    v-el-drag-dialog
    append-to-body
    :visible.sync="dialogVisible"
    title="选择设备"
    class="small-padding"
    width="1000px"
    :modal="false"
    top="3vh">
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="elevator-iot/device/row-statistics/page">
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search label="设备名称">
            <el-input v-model.trim="filter.name" clearable></el-input>
          </vm-search>
          <vm-search label="设备型号">
            <vm-dict-select v-model="filter.model" type="iotDeviceModel"></vm-dict-select>
          </vm-search>
          <vm-search label="楼盘名称">
            <el-input v-model.trim="filter.realEstateName" clearable></el-input>
          </vm-search>
          <vm-search label="楼宇名称">
            <el-input v-model.trim="filter.buildingName" clearable></el-input>
          </vm-search>
          <vm-search label="电梯注册代码">
            <el-input v-model.trim="filter.regCode" clearable></el-input>
          </vm-search>
          <vm-search label="电梯出厂编号">
            <el-input v-model.trim="filter.factoryCode" clearable></el-input>
          </vm-search>
          <vm-search label="自">
            <el-date-picker v-model.trim="filter.lastHeartbeatTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" clearable></el-date-picker>
            <span class="size">以来在线</span>
          </vm-search>
          <vm-search label="是否在线">
            <el-select v-model="filter.online">
              <el-option :value="true" label="是"></el-option>
              <el-option :value="false" label="否"></el-option>
            </el-select>
          </vm-search>
        </div>
      </template>
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-table
            :data="props.row.ports"
            label-position="left"
            class="demo-table-expand">
            <el-table-column align="scope.row.center" type="index" width="50"></el-table-column>



            <el-table-column prop="name" label="端口号" align="center">
            </el-table-column>
            <el-table-column prop="iotDeviceName" label="设备名称" align="center">
            </el-table-column>
            <el-table-column prop="lastHeartbeatTime" label="上次心跳时间" align="center">
            </el-table-column>
            <el-table-column prop="online" label="是否在线" align="center">
              <template slot-scope="scope">
                {{scope.row.online?"是":"否"}}
              </template>
            </el-table-column>
            <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate">
              <template slot-scope="scope">
                <el-button type="primary" plain @click="onSelect(scope.row,props.row)">选择</el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="name" label="设备名称/编号" align="center"></el-table-column>
      <el-table-column prop="iotProtocolSourceDesc" label="物联网协议来源" align="center"></el-table-column>
      <el-table-column prop="iotProtocolVersion" label="协议版本" align="center"></el-table-column>
      <el-table-column prop="iotSupportBoardTypeDesc" label="支持主板" align="center"></el-table-column>
      <el-table-column prop="modelDesc" label="设备型号" align="center"></el-table-column>
      <el-table-column prop="realEstateName" label="楼盘名称" align="center">
        <template slot-scope="scope">
          {{scope.row.realEstateName}}-{{scope.row.buildingName}}
        </template>
      </el-table-column>
      <el-table-column prop="elevatorQuantity" label="绑定电梯数量" align="center"></el-table-column>

      <el-table-column label="在线状态" align="center" width="90">
        <template slot-scope="scope">
          <el-tag :type="scope.row.online?'success':'info'">{{scope.row.online?'在线':'离线'}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="上次心跳时间" align="center" prop="lastHeartbeatTime">
      </el-table-column>
    </vm-table>
  </el-dialog>
</template>
<script>
  export default {
    components: {},
    data() {
      return {
        dialogVisible: false,
        filter: {
          name: "",
          customerName: "",
          wbManagerName: "",
          status: "",
        },
      };
    },
    methods: {
      open(id) {
        this.filter={};
        this.dialogVisible = true;
        this.id = id;
        this.$nextTick(() => {
          this.$refs.vmTable.getList(1);
        });

      },
      onSelect(row,line) {
        this.dialogVisible = false;
        const obj ={...line,...row,iotDevicePort:row.name,iotCode:row.name?line.name+":"+row.name:"",elevatorId:this.id,id:"",iotDeviceModel:line.model};
        this.$emit("select", obj);
      },

    },
  };
</script>
<style lang="scss" scoped>
</style>
