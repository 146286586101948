<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    title="查看历史统计数据"
    width="900px"
    top="3vh"
    :modal="false"
    @close="$reset('form')">
    <!--    <el-date-picker-->
    <!--      v-model="value1"-->
    <!--      v-loading="loading"-->
    <!--      type="date"-->
    <!--      :picker-options="pickerOptions"-->
    <!--      style="margin-bottom: 20px"-->
    <!--      placeholder="选择日期"-->
    <!--      @change="onChangeDate">-->
    <!--    </el-date-picker>-->
    <el-button type="primary" style="margin-bottom: 10px" @click="printSomething">打印</el-button>
    <div id="content1" ref="content">
      <talbe

        cellspacing="0"
        cellpadding="0"
        style="border-collapse:collapse; page-break-after: always;"
      >
        <!--        <tr>-->
        <!--          <td colspan="6">-->
        <!--            报告日期：{{$moment(value1).format('YYYY-MM-DD')}}-->
        <!--          </td>-->
        <!--        </tr>-->
        <tr>
          <td class="rpBoxTitle" colspan="6">电梯基础信息</td>
        </tr>
        <tr>
          <td class="rpTitle">楼盘名称</td>
          <td class="rpContent">{{data.realEstateName}}</td>
          <td class="rpTitle">楼宇名称</td>
          <td class="rpContent">{{data.buildingName}}</td>
          <td class="rpTitle">内部编号</td>
          <td class="rpContent">{{data.elevatorName}}</td>
        </tr>
        <tr>
          <td class="rpTitle">注册代码</td>
          <td class="rpContent">{{data.regCode}}</td>
          <td class="rpTitle">电梯品牌</td>
          <td class="rpContent">{{data.brandName}}</td>
          <td class="rpTitle">电梯型号</td>
          <td class="rpContent">{{data.modelName}}</td>
        </tr>
        <tr>
          <td class="rpTitle">电梯类别</td>
          <td class="rpContent">{{data.categoryDesc}}</td>
          <td class="rpTitle">电梯品种</td>
          <td class="rpContent">{{data.varietyDesc}}</td>
          <td class="rpTitle">使用场所</td>
          <td class="rpContent">{{data.useTypeDesc}}</td>
        </tr>
        <tr>
          <td class="rpTitle">使用单位</td>
          <td class="rpContent">{{data.usingCompanyName}}</td>
          <td class="rpTitle">联系人</td>
          <td class="rpContent">{{data.usingContactName}}</td>
          <td class="rpTitle">联系电话</td>
          <td class="rpContent">{{data.usingContactPhone}}</td>
        </tr>
        <tr>
          <td class="rpTitle">维保单位</td>
          <td class="rpContent">{{data.maintUnit}}</td>
          <td class="rpTitle">联系人</td>
          <td class="rpContent">{{data.maintContactName}}</td>
          <td class="rpTitle">联系电话</td>
          <td class="rpContent">{{data.maintContactPhone}}</td>
        </tr>
        <tr>
          <td class="rpTitle">电梯状态</td>
          <td class="rpContent">{{data.statusDesc}}</td>
          <td class="rpTitle">物联网设备编号</td>
          <td class="rpContent">{{data.iotDeviceName}}</td>
          <td class="rpTitle">端口号</td>
          <td class="rpContent">{{data.iotDevicePort}}</td>
        </tr>
        <tr>
          <td class="rpTitle">物联网设备状态</td>
          <td class="rpContent">{{data.deviceStatusDesc}}</td>
          <td class="rpTitle">离线次数</td>
          <td class="rpContent">{{data.offlineTimes}}</td>
          <td class="rpTitle">最近上线时间</td>
          <td class="rpContent">{{data.latestUptime}}</td>
        </tr>
        <tr>
          <td class="rpTitle">出厂日期</td>
          <td class="rpContent">{{data.factoryDate}}</td>
          <td class="rpTitle">出厂编号</td>
          <td class="rpContent">{{data.factoryCode}}</td>
          <td class="rpTitle">梯龄</td>
          <td class="rpContent">{{data.elevatorAge}}</td>
        </tr>
        <tr>
          <td class="rpTitle">层、站、门数</td>
          <td class="rpContent">{{data.landingDoor}}</td>
          <td class="rpTitle">额定载重（Kg）</td>
          <td class="rpContent">{{data.ratedLoads}}</td>
          <td class="rpTitle">额定速度（m/s）</td>
          <td class="rpContent">{{data.ratedSpeed}}</td>
        </tr>
        <tr><td class="rpBoxTitle" colspan="6">电梯运行数据</td></tr>
        <tr>
          <td class="rpTitle" rowspan="4">维保统计</td>
          <td class="rpTitle">保养次数</td>
          <td class="rpContent">{{data.maintainNum}}</td>
          <td class="rpTitle" rowspan="4">运行统计</td>
          <td class="rpTitle">运行时长</td>
          <td class="rpContent">{{data.runningTime}}</td>
        </tr>
        <tr>
          <td class="rpTitle">维修次数</td>
          <td class="rpContent">{{data.repairNum}}</td>
          <td class="rpTitle">运行次数</td>
          <td class="rpContent">{{data.runningCount}}</td>
        </tr>
        <tr>
          <td class="rpTitle">困人次数</td>
          <td class="rpContent">{{data.trappedNum}}</td>
          <td class="rpTitle">开关门次数</td>
          <td class="rpContent">{{data.doorOpeningCount}}</td>
        </tr>
        <tr>
          <td class="rpTitle">检验检测次数</td>
          <td class="rpContent">{{data.testNum}}</td>
          <td class="rpTitle">运行里程(m)</td>
          <td class="rpContent">{{data.runningDistance}}</td>
        </tr>
        <tr><td class="rpBoxTitle" colspan="6">维保记录</td></tr>
        <tr>
          <td class="rpTitle" colspan="3">最近记录</td>
          <td class="rpTitle" colspan="3">本期记录</td>
        </tr>
        <tr>
          <td class="rpTitle" rowspan="4">保养</td>
          <td class="rpTitle">保养日期</td>
          <td class="rpContent">{{data.planDate}}</td>
          <td class="rpTitle" rowspan="4">保养</td>
          <td class="rpTitle">保养工单号</td>
          <td class="rpContent">{{data.maintainOrderCode}}</td>
        </tr>
        <tr>
          <td class="rpTitle">保养类型</td>
          <td class="rpContent">{{data.maintainTypeDesc}}</td>
          <td class="rpTitle">工单状态</td>
          <td class="rpContent">{{data.orderStatusDesc}}</td>
        </tr>
        <tr>
          <td class="rpTitle">保养用时(min)</td>
          <td class="rpContent">{{data.maintTime}}</td>
          <td class="rpTitle">保养人员</td>
          <td class="rpContent">{{data.maintainName}}</td>
        </tr>
        <tr>
          <td class="rpTitle">距前次保养间隔天数</td>
          <td class="rpContent">{{data.maintInterval}}</td>
          <td class="rpTitle">最迟下次保养日期</td>
          <td class="rpContent">{{$moment(data.lastMaintainDate).format("YYYY-MM-DD")}}</td>
        </tr>
        <tr>
          <td class="rpTitle" rowspan="3">维修</td>
          <td class="rpTitle">维修日期</td>
          <td class="rpContent">{{data.repairDate}}</td>
          <td class="rpTitle" rowspan="3">维修</td>
          <td class="rpTitle">最长到场用时</td>
          <td class="rpContent">{{data.maxArriveTime}}</td>
        </tr>
        <tr>
          <td class="rpTitle">故障名称</td>
          <td class="rpContent">{{data.faultName}}</td>
          <td class="rpTitle">平均维修用时</td>
          <td class="rpContent">{{data.aveRepairTime}}</td>
        </tr>
        <tr>
          <td class="rpTitle">维修用时(min)</td>
          <td class="rpContent">{{data.repairTime}}</td>
          <td class="rpTitle">故障间隔天数</td>
          <td class="rpContent">{{data.faultIntervalDays}}</td>
        </tr>
        <tr>
          <td class="rpTitle" rowspan="3">困人</td>
          <td class="rpTitle">困人时间</td>
          <td class="rpContent">{{data.trappedTime}}</td>
          <td class="rpTitle" rowspan="3">困人</td>
          <td class="rpTitle">最长到场用时</td>
          <td class="rpContent">{{data.maxTrapArriveTime}}</td>
        </tr>
        <tr>
          <td class="rpTitle">到场用时</td>
          <td class="rpContent">{{data.rescueTime}}</td>
          <td class="rpTitle">平均救援用时</td>
          <td class="rpContent">{{data.aveSaveTime}}</td>
        </tr>
        <tr>
          <td class="rpTitle">救援用时</td>
          <td class="rpContent">{{data.rescueTime}}</td>
          <td class="rpTitle">困人间隔天数</td>
          <td class="rpContent">{{data.trappedInterval?data.trappedInterval:'暂无困人'}}</td>
        </tr>
        <tr>
          <td class="rpTitle" rowspan="4">检验检测</td>
          <td class="rpTitle">上次检验检测日期</td>
          <td class="rpContent"></td>
          <td class="rpTitle" rowspan="4">检验检测</td>
          <td class="rpTitle" rowspan="2">下次检验检测日期</td>
          <td class="rpContent" rowspan="2"></td>
        </tr>
        <tr>
          <td class="rpTitle">上次检验检测类型</td>
          <td class="rpContent"></td>
        </tr>
        <tr>
          <td class="rpTitle">上次检验检测人员</td>
          <td class="rpContent"></td>
          <td class="rpTitle" rowspan="2">下次检验检测类型</td>
          <td class="rpContent" rowspan="2"></td>
        </tr>
        <tr>
          <td class="rpTitle">是否一次性通过</td>
          <td class="rpContent"></td>
        </tr>
        <tr>
          <td class="rpTitle" rowspan="5">更换件</td>
          <td class="rpTitle">更换件日期</td>
          <td class="rpContent"></td>
          <td class="rpTitle" rowspan="5">更换件</td>
          <td class="rpTitle">更换件数量</td>
          <td class="rpContent"></td>
        </tr>
        <tr>
          <td class="rpTitle">配件名称</td>
          <td class="rpContent"></td>
          <td class="rpTitle" colspan="2">换件明细</td>
        </tr>
        <tr>
          <td class="rpTitle">配件品牌</td>
          <td class="rpContent"></td>
          <td class="rpTitle">配件名称</td>
          <td class="rpContent"></td>
        </tr>
        <tr>
          <td class="rpTitle">配件型号</td>
          <td class="rpContent"></td>
          <td class="rpTitle">配件型号</td>
          <td class="rpContent"></td>
        </tr>
        <tr>
          <td class="rpTitle">配件数量</td>
          <td class="rpContent"></td>
          <td class="rpTitle">配件数量</td>
          <td class="rpContent"></td>
        </tr>
      </talbe>
    </div>
    <span slot="footer">
      <el-button @click="dialogVisible=false">{{$l("common.cancel", "取消")}}</el-button>
    </span>
  </el-dialog>
</template>

<script>



  import moment from "moment";
  import print from "print-js";

  export default {
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        tabActive: "first",
        floorStart: 0,
        floorEnd: 0,
        loading:false,
        id:0,
        model: [],
        filter:{
          orderColumnName:"timestamp",
        },
        data:{},
        value1:"",
        pickerOptions: {
          disabledDate:(time)=> {
            return time.getTime() > Date.now()|| !this.model.arr.some(item=>item.time===time.getTime());
          },
          // shortcuts: [{
          //   text: "最新一次",
          //   onClick(picker) {
          //     picker.$emit("pick", new Date());
          //   },
          // }, {
          //   text: "最近一次",
          //   onClick(picker) {
          //     const date = new Date();
          //     date.setTime(date.getTime() - 3600 * 1000 * 24 * 14);
          //     picker.$emit("pick", date);
          //   },
          // }],
        },
      };
    },

    methods: {
      addWatermark(content) {
        this.$watermark.set(content,this.$refs.content);
      },
      printSomething() {
        console.log(138,this.$refs.content);

        // const table = document.querySelector(".tb");
        // table.style.margin="20px auto";
        // table.style.margin = "0 0";
        print({
          printable:"content1",
          type:"html",
          targetStyles:["*"],
          onPrintDialogClose:()=>{

          }});


      },
      onChangeDate(event) {
        console.log(event.getTime(),299);
        const num = this.model.arr.findIndex(item=>item.time===event.getTime());
        this.getDetail(this.model.arr[num].id);
      },
      open(id) {
        this.model.id = id;
        // this.model.elevatorId = id;
        this.id =id;
        this.dialogVisible = true;
        this.getData();
      },
      openDeatail(id) {
        this.dialogVisible = true;
        this.getDetail(id);
      },
      getData() {
        if (this.id) {
          const parmars  = {...this.filter, elevatorId:this.id};
          this.filter = {...this.filter, elevatorId:this.id};
          // this.contentLoading = true;
          this.loading=true;
          this.$http.get("basic/elevator-half-month-operation-report/list",parmars).then(data => {
            this.contentLoading = false;
            const arr = data.map(item=>{return {time:moment(item.createTime).format("YYYY-MM-DD"),id:item.id};});
            const list = arr.map(item=>{
              return {time:moment(item.time).toDate().getTime(),id:item.id};});
            this.model.arr = list;
            this.loading=false;
            if (list instanceof Array&&list.length>0) {
              this.value1=moment(list[list.length-1].time);

              this.getDetail(list[list.length-1].id);
            }
          }).catch(()=>{
            this.loading=false;
          });
        } else {
          // this.initMap();
        }
      },
      getDetail(id) {

        this.$http.get(`basic/elevator-half-month-operation-report/${id}`).then(data => {
          let statusDesc="";
          if (data.elevatorStatus===0) {
            statusDesc="停用";
          }else if (data.elevatorStatus===1) {
            statusDesc="在用";
          }else {
            statusDesc="注销";
          }
          this.addWatermark(data.realEstateName+"-"+data.regCode);
          this.data = {...data,statusDesc};
          console.log(data,333);
        });
      },

    },
  };
</script>

<style lang="scss" scoped>
.rpTitle{
  width:200px;
  text-align: center;
  border: 1px solid black;
}
.rpContent{
  width: 200px;
  text-align: center;
  border: 1px solid black;
}
.rpBoxTitle{
  background: #92d050;
  width: 100%;
  font-size: 20px;
  text-align: center;
  border: 1px solid #92d050;
}
</style>
