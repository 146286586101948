<template>
  <el-dialog
    v-el-drag-dialog
    append-to-body
    :visible.sync="dialogVisible"
    title="选择项目"
    class="small-padding"
    width="1000px"
    :modal="false"
    top="3vh">
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="project/maintenance-project/page">
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search :label="$l('project.name','项目名称')">
            <el-input v-model.trim="filter.name" clearable></el-input>
          </vm-search>
          <vm-search label="签订日期">
            <el-input v-model.trim="filter.customerCompanyName" clearable></el-input>
          </vm-search>

          <vm-search label="维保班组">
            <vm-dict-select v-model="filter.status" type="projectStatus"></vm-dict-select>
          </vm-search>
          <vm-search label="队长姓名">
            <el-select v-model="filter.type" placeholder="请选择">
              <el-input v-model.trim="filter.customerCompanyName" clearable></el-input>
            </el-select>
          </vm-search>
          <vm-search label="队长电话">
            <el-select v-model="filter.type" placeholder="请选择">
              <el-input v-model.trim="filter.customerCompanyName" clearable></el-input>
            </el-select>
          </vm-search>
          <vm-search label="开始时间">
            <el-date-picker
              v-model="filter.startTime"
              style="width: 185px"
              type="date"
              value-format="yyyy-MM-dd 00:00:00">
            </el-date-picker>
          </vm-search>
          <vm-search label="结束时间">
            <el-date-picker
              v-model="filter.endTime"
              style="width: 185px"
              type="date"
              value-format="yyyy-MM-dd 23:59:59">
            </el-date-picker>
          </vm-search>
        </div>
      </template>
      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="name" :label="$l('project.name','项目名称')"></el-table-column>

      <el-table-column prop="customerCompanyName" label="签订日期"></el-table-column>
      <el-table-column prop="realEstateName" label="维保班组"></el-table-column>
      <el-table-column prop="typeDesc" label="队长姓名/队长电话"></el-table-column>
      <el-table-column prop="startTime" label="开始时间">
        <template slot-scope="scope">
          {{scope.row.startTime?$moment(scope.row.startTime).format('YYYY-MM-DD'):''}}
        </template>
      </el-table-column>
      <el-table-column prop="endTime" label="结束时间">
        <template slot-scope="scope">
          {{scope.row.endTime?$moment(scope.row.endTime).format('YYYY-MM-DD'):''}}
        </template>
      </el-table-column>
      <el-table-column width="70" :label="$l('common.function','操作')" align="center">
        <template slot-scope="scope">
          <el-button type="primary" plain @click="onSelect(scope.row)">选择</el-button>
        </template>
      </el-table-column>
    </vm-table>
  </el-dialog>
</template>
<script>
  export default {
    components: {},
    data() {
      return {
        dialogVisible: false,
        filter: {
          name: "",
          customerName: "",
          wbManagerName: "",
          status: "",
          index:0,
        },
      };
    },
    methods: {
      open(index) {
        this.filter={};
        this.dialogVisible = true;
        this.index = index;
        this.$nextTick(() => {
          this.$refs.vmTable.getList(1);
        });
      },
      onSelect(row) {
        this.dialogVisible = false;
        this.$emit("select", row,this.index);
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
