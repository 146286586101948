<template>
  <el-dialog
    v-el-drag-dialog
    append-to-body
    :visible.sync="dialogVisible"
    title="选择楼宇"
    class="small-padding"
    width="1200px"
    :modal="false"
    top="3vh">
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="real-estate/building/page">
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search label="楼盘名称">
            <vm-autocomplete :filter.sync="filter.realEstateName" url="customer-relationship/customer-company/list" value-key="name" :value="{usingCompanyName:'name'}"></vm-autocomplete>
          </vm-search>
          <vm-search label="楼宇">
            <vm-autocomplete :filter.sync="filter.name" url="real-estate/list" value-key="name" :value="{developerCompanyName:'name'}"></vm-autocomplete>
          </vm-search>
          <vm-search label="是否有机房">
            <el-select v-model="filter.machineRoom">
              <el-option :value="true" label="是"></el-option>
              <el-option :value="false" label="否"></el-option>
            </el-select>
          </vm-search>
        </div>
      </template>

      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="usingCompanyName" label="使用单位" align="center"></el-table-column>
      <el-table-column prop="realEstateName" label="楼盘名称" align="center"></el-table-column>
      <el-table-column prop="name" label="楼宇名称" align="center"></el-table-column>
      <el-table-column prop="aboveGroundFloorCount" label="地上楼层计数" align="center"></el-table-column>
      <el-table-column prop="underGroundFloorCount" label="地下楼层计数" align="center"></el-table-column>

      <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate">
        <template slot-scope="scope">
          <el-button type="primary" plain @click="onSelect(scope.row)">选择</el-button>
        </template>
      </el-table-column>
    </vm-table>
    <edit-page ref="editPage" @save-success="getList(-1)"></edit-page>
  </el-dialog>
</template>
<script>
  import EditPage from "../../views/elevatorUsingBuilding/Edit";
  import VmAutocomplete from "@/components/VmAutocomplete";
  export default {
    components: {VmAutocomplete, EditPage},
    data() {
      return {
        dialogVisible: false,
        option:[
          {
            label:"是",
            value:true,
          },
          {
            label: "否",
            value:false,
          },
        ],
        filter: {
          name: "",
          customerName: "",
          wbManagerName: "",
          status: "",
        },
      };
    },
    methods: {
      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
      },
      open() {
        this.filter={};
        this.dialogVisible = true;
        this.$nextTick(() => {
          this.$refs.vmTable.getList(1);
        });
      },
      onSelect(row) {
        this.dialogVisible = false;
        this.$emit("select", row);
      },

    },
  };
</script>
<style lang="scss" scoped>
</style>
