<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :title="model.id?'编辑故障':'新增故障'"
    width="500px"
    top="3vh"
    :modal="false"
    @close="$reset('form')">
    <el-form
      ref="form"
      v-loading="contentLoading"
      :model="model"
      class="form"
      :label-width="$l('device.labelWidth','80px')">
      <el-form-item label="故障代码" prop="faultCode">
        <el-input v-model="model.faultCode" disabled :placeholder="$l('common.enter','请输入')"></el-input>
      </el-form-item>
      <el-form-item label="故障集" prop="iotFaultSet">
        <vm-dict-select v-model.trim="model.iotFaultSet" disabled type="iotFaultSet"></vm-dict-select>
      </el-form-item>
      <el-form-item label="故障名称" prop="manufacturerName">
        <el-input v-model="model.faultName" disabled type="manufacturerName"></el-input>
      </el-form-item>
      <el-form-item label="故障描述" prop="manufacturerName">
        <el-input v-model="model.description" disabled type="manufacturerName"></el-input>
      </el-form-item>
      <el-form-item label="电梯停梯" prop="manufacturerName">
        <el-select v-model="model.elevatorStopped" disabled>
          <el-option :value="true" label="是"></el-option>
          <el-option :value="false" label="否"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="人员被困" prop="manufacturerName">
        <el-select v-model="model.peopleTrapped" disabled>
          <el-option :value="true" label="是"></el-option>
          <el-option :value="false" label="否"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="故障等级" prop="manufacturerName">
        <el-input v-model="model.level" disabled type="manufacturerName"></el-input>
      </el-form-item>
      <el-form-item label="故障原因" prop="manufacturerName">
        <el-input v-model="model.cause" disabled type="manufacturerName"></el-input>
      </el-form-item>
      <el-form-item label="故障位置" prop="manufacturerName">
        <el-input v-model="model.position" disabled type="manufacturerName"></el-input>
      </el-form-item>
      <el-form-item label="解决方案" prop="manufacturerName">
        <el-input v-model="model.solution" disabled type="manufacturerName"></el-input>
      </el-form-item>
      <el-form-item label="处理对策" prop="manufacturerName">
        <el-input v-model="model.orderProposal" disabled type="manufacturerName"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="dialogVisible=false">{{$l("common.cancel","取消")}}</el-button>
    </span>
  </el-dialog>
</template>
<script>


  export default {
    components: {},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        model: {
          id: 0,
          no: "",
          source: "",
          isp: "",
          iccid: "",
        },

      };
      
    },
    methods: {
      open(id) {
        this.model.id = id;
        this.dialogVisible = true;
        this.getData();
      },
      getData() {
        if (this.model.id) {
          this.contentLoading = true;
          this.$http.get(`/basic/elevator-fault-dict/${this.model.id}`).then(data => {
            this.contentLoading = false;
            this.model = data;
          });
        }
      },
      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            this.$http
              .save("/basic/elevator-fault-dict", this.model)
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success("保存成功");
              })
              .catch(() => {
                this.submitLoading = false;
              });
          } else {
            this.$message.error("有不符合要求数据，请修改后提交");
          }
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
