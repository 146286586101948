import { render, staticRenderFns } from "./BuildingSelect.vue?vue&type=template&id=036b4176&scoped=true&"
import script from "./BuildingSelect.vue?vue&type=script&lang=js&"
export * from "./BuildingSelect.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "036b4176",
  null
  
)

export default component.exports