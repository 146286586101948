<template>
  <el-dialog
    v-el-drag-dialog
    append-to-body
    :visible.sync="dialogVisible"
    title="选择企业"
    class="small-padding"
    width="1000px"
    :modal="false"
    top="3vh">
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="companies">
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search :label="$l('company.name','企业名称')">
            <el-input v-model.trim="filter.name" clearable></el-input>
          </vm-search>
          <vm-search :label="$l('company.creditCode','统一社会信用代码')">
            <el-input v-model.trim="filter.creditCode" clearable></el-input>
          </vm-search>
        </div>
      </template>
      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="name" :label="$l('company.name','企业名称')"></el-table-column>
      <el-table-column prop="shortName" label="企业简称" align="center"></el-table-column>
      <el-table-column prop="typeDesc" label="企业类型" align="center"></el-table-column>
      <el-table-column prop="creditCode" label="统一社会信用代码" align="center"></el-table-column>
      <el-table-column prop="contactName" label="联系人" align="center"></el-table-column>
      <el-table-column prop="contactPhone" label="联系电话" align="center"></el-table-column>
      <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate">
        <template slot-scope="scope">
          <el-button type="primary" plain @click="onSelect(scope.row)">选择</el-button>
        </template>
      </el-table-column>
    </vm-table>
  </el-dialog>
</template>
<script>
  export default {
    components: {},
    data() {
      return {
        dialogVisible: false,
        filter: {
          name: "",
          creditCode: "",
        },
      };
    },
    methods: {
      open() {
        this.filter={};
        this.dialogVisible = true;
        this.$nextTick(() => {
          this.$refs.vmTable.getList(1);
        });
      },
      onSelect(row) {
        this.dialogVisible = false;
        this.$emit("select", row);
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
